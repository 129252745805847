.card .card-block code, .card .card-body code {
    background-color: transparent !important;
}

// Override GLobal react datepicker stylee
.react-datepicker__input-container {
    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: 
        #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid
        #ced4da;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.dud-logout {
    cursor: pointer;
}

// Override Global TD Style
.table th, .table td {
    vertical-align: middle;
    font-size: 14px;
}

// Custom Global Badge
.custom-badge {
    padding: 8px 12px;
    font-size: 12px;
}

// Custom Global Allert Message
.customAlert {
    position: absolute !important;
    top: 0;
    right: 10px;
    z-index: 999;
    width: 300px;
}

// Custom Color
.btn-orange {
    background-color: orange !important;
    border-color: orange !important;
    &:hover {
        background-color: #e69500 !important;
    }
}

// Override btn-sm
.btn-sm {
    padding: 6px 9px !important;
}

// LLAMDAO CORNER
.llamado-corner-setter .dropdown-toggle{
    padding: 0px 5px;
}

// Road Map Custom Styles
.arenaRoadMap {
    .roadMapTD {
        padding: 4px !important;
        text-align: center !important;
    }
    
    .meron-txt {
        color: #fff;
        background-color: #c56161;
        border-radius: 20px;
        font-size: 12px !important;
        font-weight: normal !important;
        padding: 3px 6px;
    }
    
    .draw-txt {
        color: #fff;
        background-color: #428b47;
        border-radius: 20px;
        font-size: 12px !important;
        font-weight: normal !important;
        padding: 3px 6px;
    }
    
    .pending-txt {
        color: #fff;
        background-color: #ad8713;
        border-radius: 20px;
        font-size: 12px !important;
        font-weight: normal !important;
        padding: 3px 8px;
    }
    
    .wala-txt {
        color: #fff;
        background-color: #357eb8;
        border-radius: 20px;
        font-size: 12px !important;
        font-weight: normal !important;
        padding: 3px 6px;
    }
    
    .fightNo-txt {
        font-size: 12px;
        font-weight: normal;
        padding: 0 !important;
        color: #aaaaaa;
    }

    .cancelled-txt {
        color: #fff;
        background-color: #808080 ;
        border-radius: 20px;
        font-size: 12px !important;
        font-weight: normal !important;
        padding: 3px 6px;
    }
}

.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }


.controllerLoader {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
    .inner-controllerLoader {
        display: table-cell;
        vertical-align: middle;
        color: white;
    }
}

.hider {
    display: none;
}

.hiderLoader {
    display: none !important;
}

  .loaderSpinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loaderLabel {
    padding-bottom: 2px;
  }

  .loaderSpinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    margin-bottom: -6px;
    animation: spinLoader 1s ease-in-out infinite;
    -webkit-animation: spinLoader 1s ease-in-out infinite;
  }
  
  @keyframes spinLoader {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spinLoader {
    to { -webkit-transform: rotate(360deg); }
  }

  .activeMatchBtn {
    .loaderSpinner {
        width: 12px;
        height: 12px;
        border: 2px solid rgba(142,35,137,.3) !important;
        border-top-color: #c92ec2 !important;
        margin-bottom: -2px;
    }
  }

  .matchActivatorContainer {
    position: relative;
    text-align: center;
    display: inline-block;
    margin-right: 16px;
    .controllerLoader {
        width:  100%;
    }
    .inner-controllerLoader {
        .loaderSpinner {
            width: 16px;
            height: 16px;
            margin-bottom: -3px;
        }
    }
  }

.modal-winningCorner {
    padding: 30px;
}
.modal-winningCorner--meron {
    background-color: #f44236;
}
.modal-winningCorner--draw {
    background-color: #28a745;
}
.modal-winningCorner--wala {
    background-color: #0000dc
}
  .modal-winningCorner-label {
    text-align: center;
    font-weight: bold;
    color: white;
  }

  .modal-cancel--match {
    background-color: #e69500;
    h2, h3 {
        text-align: center;
        color: white;
    }
  }